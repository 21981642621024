html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.main-layout {
  min-height: 100vh;
}

.form {
  &__input {
    margin-bottom: 15px;
  }
}
.header {
  height: 90px;
}
.footer {
  margin-top: auto;
}

.loader-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-wrapper {
  margin: 16px;
  text-align: center;
}

.content-wrapper {
  margin: 0 16px;
}
.product-table-nav {
  margin-bottom: 48px
}
.product-form-aria-button {
  margin-top: 48px;
}
.background-card-deleted {
  opacity: 0.5;
}
.main-grid {
  width:100vw;
  min-height: 100%;
}